<template>
    <CDropdown
        v-if="$root.loginUser"
        inNav
        class="c-header-nav-items"
        placement="bottom-end"
        add-menu-classes="pt-0"
    >
        <template #toggler>
            <CHeaderNavLink>
                {{ $root.loginUser.attributes.name }} ▼
            </CHeaderNavLink>
        </template>
        <CDropdownHeader tag="div" class="text-center" color="light">
            <strong>アカウント</strong>
        </CDropdownHeader>
        <CDropdownItem>
            名前 : {{ $root.loginUser.attributes.name }}
        </CDropdownItem>
        <CDropdownItem>
            役割 : {{ userRole($root.loginUser.attributes.role) }}
        </CDropdownItem>
        <CDropdownItem @click="logout">
            ログアウト
        </CDropdownItem>
    </CDropdown>
</template>
<script>
export default {
    name: 'TheHeaderDropdownAccnt',
    methods: {
        logout: function () {
            window.location.href = '/logout';
        }
    },
    computed: {
        userRole: function () {
            return function (role) {
                switch (role){
                    case 0:
                        return '一般'
                    case 1:
                        return '管理者'
                    default:
                        return '-'
                }
            }
        }
    }
}
</script>

<style scoped>
.c-icon {
    margin-right: 0.3rem;
}
</style>
