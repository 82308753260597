<template>
  <CFooter :fixed="false">
    <div class="mfs-auto">
      <p>Copyright (C) 2021 rokumaru All Rights Reserved.</p>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
