export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavTitle',
        _children: ['一般メニュー']
      },
      {
        _name: 'CSidebarNavItem',
        name: '日報一覧',
        to: '/daily-report-select',
        icon: 'cil-pencil'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Dr指示書',
        to: '/doctor-direction-select',
        icon: 'cil-pencil'
      },
      {
        _name: 'CSidebarNavItem',
        name: '訪問看護計画書',
        to: '/home-nursing-plan-select',
        icon: 'cil-pencil'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'ヘルスチェックレポート',
        to: '/medical-cooperation-nursing-report-select',
        icon: 'cil-pencil'
      },
      {
        _name: 'CSidebarNavItem',
        name: '訪問看護報告書',
        to: '/home-nursing-report-select',
        icon: 'cil-pencil'
      },
    ]
  }
]
