<template>
  <CSidebar
      v-if="$root.loginUser"
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="">
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="$root.loginUser.attributes.role === 1 ? $options.nav : $options.navGeneral"/>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from './_nav'
import navGeneral from './_navGeneral'

export default {
  name: 'TheSidebar',
  nav,navGeneral,
  computed: {
    show () {
      return this.$store.state.sidebarShow
    },
    minimize () {
      return this.$store.state.sidebarMinimize
    }
  }
}
</script>
